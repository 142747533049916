// eslint-disable-next-line
import axios from "axios";
export default {
  _url: "",

  pluginSettings: window.pluginSettings,

  setToken(token) {
    this._token = token;
  },

  setApiToken(token) {
    this._apitoken = token;
  },

  getToken() {
    return this._token;
  },

  getApiToken() {
    return this._apitoken;
  },

  getHeadersWithDefaults(apiKey) {
    let newHeader = {
      "X-CSRF-TOKEN": this._token,
      "api-token-key": apiKey,
      "Content-Type": "application/json",
    };

    if (apiKey !== "") {
      newHeader.Authorization = `Bearer ${apiKey}`;
    }

    return newHeader;
  },

  init(apiUrl) {
    this._url = apiUrl;
    return this;
  },

  startRender(timestamp) {
    const request = new Request(
      `${this._url}/test/starttestrender/${timestamp}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "api-token": this.pluginSettings.token,
        },
      }
    );
    return fetch(request);
  },

  async approveShaderTexture(bodyData) {
    console.log(bodyData);
    // Create FormData object to encode files

    // Make POST request to /upload endpoint
    return axios.post(`${this._url}/texture/shader`, bodyData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async submitModelStatus(bodyData) {
    console.log(bodyData);
    // Create FormData object to encode files

    // Make POST request to /model/status endpoint
    return axios.post(`${this._url}/model/status`, bodyData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async submitTextureStatus(bodyData) {
    console.log(bodyData);
    // Create FormData object to encode files

    // Make POST request to /texture/status endpoint
    return axios.post(`${this._url}/texture/status`, bodyData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async createModelJob(bodyData) {
    console.log(bodyData);
    // Make POST request to /createModelJob endpoint
    return axios.post(`${this._url}/createModelJob`, bodyData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async createTextureJob(bodyData) {
    console.log(bodyData);
    // Make POST request to /createTextureJob endpoint
    return axios.post(`${this._url}/createTextureJob`, bodyData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async uploadModelFile(files, metadata) {
    console.log(files, metadata);
    // Create FormData object to encode files
    const formData = new FormData();
    // Loop through files and add to FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // Add metadata to FormData
    formData.append("metadata", JSON.stringify(metadata));

    // Make POST request to /upload endpoint
    return axios.post(`${this._url}/upload/model`, formData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "multipart/form-data",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async uploadTextureFile(files, metadata) {
    console.log(files, metadata);
    // Create FormData object to encode files
    const formData = new FormData();
    // Loop through files and add to FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // Add metadata to FormData
    formData.append("metadata", JSON.stringify(metadata));

    // Make POST request to /upload/texture endpoint
    return axios.post(`${this._url}/upload/texture`, formData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "multipart/form-data",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  async uploadFootFile(files, metadata) {
    console.log(files, metadata);
    // Create FormData object to encode files
    const formData = new FormData();
    // Loop through files and add to FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // Add metadata to FormData
    formData.append("metadata", JSON.stringify(metadata));

    // Make POST request to /upload endpoint
    return axios.post(`${this._url}/upload/foot`, formData, {
      headers: {
        // Set Content-Type header
        "Content-Type": "multipart/form-data",
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getTextureData() {
    return axios.get(`${this._url}/texturedata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getModelData() {
    return axios.get(`${this._url}/modeldata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getModelVariantData() {
    return axios.get(`${this._url}/modeldata/variant`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getModelOutputData(modelId) {
    return axios.get(`${this._url}/model-output-data`, {
      headers: {
        "api-token": this.pluginSettings.token,
        "model-id": modelId,
      },
    });
  },

  getTextureOutputData(textureId) {
    return axios.get(`${this._url}/texture-output-data`, {
      headers: {
        "api-token": this.pluginSettings.token,
        "texture-id": textureId,
      },
    });
  },

  getFootData() {
    return axios.get(`${this._url}/footdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getShaderData() {
    return axios.get(`${this._url}/shaderdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getValidShaderData() {
    return axios.get(`${this._url}/validShaderdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getModelStatusData() {
    return axios.get(`${this._url}/model/statusdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getTextureStatusData() {
    return axios.get(`${this._url}/texture/statusdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getRenderDataByModelId(modelId) {
    return axios.get(`${this._url}/renderdata/modelid/${modelId}`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  getRenderData() {
    return axios.get(`${this._url}/renderdata`, {
      headers: {
        "api-token": this.pluginSettings.token,
      },
    });
  },

  login(password) {
    const request = new Request(`${this._url}/test/login/${password}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "api-token": this.pluginSettings.token,
      },
    });
    return fetch(request);
  },
};
