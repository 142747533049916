import React from "react";
import HomeBanner from "../Components/home/HomeBanner";

const Home = () => {
  return (
    <div className="ui container">
      <HomeBanner />
    </div>
  );
};

export default Home;
