import { React } from "react";
import PropTypes from "prop-types";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import LoadingOverlay from "react-loading-overlay";

import { useSelector } from "react-redux";

import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages";
import Model from "./pages/model-page";
import ModelVariant from "./pages/model-variant-page";
import Texture from "./pages/texture-page";
// import TextureOld from "./pages/texture-page-old";
import Foot from "./pages/foot-page";
import Render from "./pages/file-render";
import AssetStatus from "./pages/asset-status";
import Info from "./pages/info-page";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { Authenticator } from "@aws-amplify/ui-react";
import ValidShader from "./pages/valid-shader-page";

// your app imports here
window.Buffer = window.Buffer || require("buffer").Buffer;

LoadingOverlay.propTypes = undefined;
Amplify.configure(awsconfig);

function App(props) {
  const isLoading = useSelector((state) => state.isLoading);

  return (
    <LoadingOverlay
      fadeSpeed={100}
      spinner
      active={isLoading}
      text="Please wait for the process to complete...."
      styles={{
        wrapper: (base) => ({
          ...base,
          width: "100%",
          height: "100%",
          overflow: isLoading ? "hidden" : "auto",
          zIndex: 1500,
        }),
        overlay: (base) => ({
          ...base,
          zIndex: 1500,
        }),
      }}
    >
      <Authenticator>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/model-page"
              element={<Model apiHelper={props.apiHelper} />}
            />
            <Route
              path="/model-variant-page"
              element={<ModelVariant apiHelper={props.apiHelper} />}
            />
            {/* <Route
              path="/texture-page"
              element={<TextureOld apiHelper={props.apiHelper} />}
            /> */}
            <Route
              path="/texture-page"
              element={<Texture apiHelper={props.apiHelper} />}
            />
            <Route
              path="/foot-page"
              element={<Foot apiHelper={props.apiHelper} />}
            />
            <Route
              path="/file-render"
              element={<Render apiHelper={props.apiHelper} />}
            />
            <Route
              path="/asset-status"
              element={<AssetStatus apiHelper={props.apiHelper} />}
            />
            <Route
              path="/info-page"
              element={<Info apiHelper={props.apiHelper} />}
            />
            <Route
              path="/valid-shader-page"
              element={<ValidShader apiHelper={props.apiHelper} />}
            />
          </Routes>
        </Router>
      </Authenticator>
    </LoadingOverlay>
  );
}

App.propTypes = {
  signOut: PropTypes.func,
  user: PropTypes.object,
};

export default withAuthenticator(App);
