import React, { useState, useRef } from "react";
//import shaderList from "../../shader_data.json";
import { useSelector } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";

const ModelStatusButton = (props) => {
  // from json
  //const listOfShader = shaderList;

  // from database
  // redux isLoading, listOfShader var
  let listOfModelStatus = useSelector((state) => state.modelStatusData);
  // console.log(listOfModelStatus);
  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [modelId, setModelId] = useState(props.model.id);
  const [statusId, setStatusId] = useState(1);
  const selectRef = useRef();

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const handleSelectInput = (e) => {
    setModelId(props.model.id);
    setStatusId(e.target.value);
    enableButton();
  };

  const submitStatus = async (e) => {
    // Define metadata object
    setModelId(props.model.id);
    let bodyData = {
      modelId: modelId,
      statusId: statusId,
    };
    //console.log(bodyData);
    // Upload files to API
    await props.apiHelper
      .submitModelStatus(bodyData)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Model's status updated successfully");
          // find status name from list of status
          let newStatus = listOfModelStatus.find(
            (status) => status.id === Number(statusId)
          );
          props.refreshModel(newStatus.id, newStatus.statusName);
          selectRef.current.value = "";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="shaders">
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <select
            ref={selectRef}
            className="ui dropdown"
            onChange={(e) => handleSelectInput(e)}
          >
            <option key="" value={""}>
              Select status
            </option>
            {listOfModelStatus.map((option) => (
              <option key={option.id} value={option.id}>
                {option["statusName"]}
              </option>
            ))}
          </select>
        </ListGroup.Item>
        <ListGroup.Item>
          {/* <button className="ui tiny button" disabled={isButtonDisabled}>
              Render File
            </button> */}
          <button
            className="ui tiny button"
            disabled={isButtonDisabled}
            onClick={() => submitStatus()}
          >
            Submit
          </button>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default ModelStatusButton;
