import { React, useEffect } from "react";
import FootHeader from "../Components/foot/FootHeader";
import FootList from "../Components/foot/FootList";

import { useDispatch } from "react-redux";

const Foot = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncFn = async () => {
      let footData = await props.apiHelper.getFootData();

      dispatch({
        type: "SET_FOOT_DATA",
        footData: footData.data,
        isLoading: false,
      });
    };
    asyncFn();
  }, []);

  return (
    <div>
      <div className="ui container">
        <FootHeader />
        <FootList apiHelper={props.apiHelper}></FootList>
      </div>
    </div>
  );
};

export default Foot;
