import React, { useState } from "react";
import TextureCard from "./TextureCard";
import { useSelector } from "react-redux";
//import textureList from "../data.json";
import Container from "react-bootstrap/Container";

const TextureList = (props) => {
  // from json
  // const listOftexture = textureList;
  //const [textureStatus, setTextureStatus] = useState(props.assetStatus);

  // from database
  const listOfTexture = useSelector((state) => state.textureData);
  //const listOfTextureStatus = useSelector((state) => state.statusData);
  // const handleTextureStatusFilterChange = (e) => {
  //   //alert(e.target.value);
  //   setTextureStatus(e.target.value);
  // };

  const filteredTexture =
    props.assetStatus == 0
      ? listOfTexture
      : listOfTexture.filter(
          (texture) => texture.assetStatusId == props.assetStatus
        );

  console.log(`new textureStatus = ${props.assetStatus}`);
  console.log(`listOfTexture.length = ${listOfTexture.length}`);
  console.log(`filteredTexture.length = ${filteredTexture.length}`);
  //console.log(`listOfTextureStatus.length = ${listOfTextureStatus.length}`);

  //const renderTextureList = filteredTexture.map((texture) => {
  return (
    <Container>
      {/* <div className="ui four cards">
        <div>
          Model Status
          <select
            className="ui dropdown"
            onChange={(e) => handleTextureStatusFilterChange(e)}
          >
            <option key="0" value="0">
              All
            </option>
            {listOfTextureStatus.map((option) => (
              <option key={option.id} value={option.id}>
                {option["statusName"]}
              </option>
            ))}
          </select>
        </div>
      </div> */}

      <div className="ui four cards">
        {filteredTexture.map((texture) => {
          return (
            <TextureCard
              key={texture.id}
              texture={texture}
              apiHelper={props.apiHelper}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default TextureList;
