import React from "react";

const ValidShaderHeader = () => {
  return (
    <div className="ui container">
      <br></br>
      <h2 className="ui header">
        Valid shaders to use for embedding in a model page
      </h2>
      <br></br>
    </div>
  );
};

export default ValidShaderHeader;
