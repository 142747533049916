import React, { forwardRef, useImperativeHandle, useState } from "react";
//import shaderList from "../../shader_data.json";
//import { useSelector } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";

const ModelRenderButton = forwardRef((props, ref) => {
  // from json
  //const listOfShader = shaderList;

  // from database
  // redux isLoading, listOfShader var
  //let listOfStatus = useSelector((state) => state.statusData);
  //let listOfRender = useSelector((state) => state.renderData);

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(
    props.assetStatusId === 2 ? false : true
  );
  const [modelId, setModelId] = useState(props.model.id);
  //const [modelStatusId, setModelStatusId] = useState(props.model.assetStatusId);
  //props.model.assetStatusId
  //const [statusId, setStatusId] = useState(1);
  const six_two_six = useState();

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const disableButton = () => {
    setButtonDisabled(true);
  };

  function handleRenderButton(newAssetStatusId) {
    // alert(`handleRenderButton newAssetStatusId ${newAssetStatusId}`);
    if (newAssetStatusId === 2) {
      enableButton();
    } else {
      disableButton();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      handleRenderButton,
    };
  });

  const submitSixTwoSix = async (e) => {
    // Define metadata object
    setModelId(props.model.id);
    let bodyData = {
      modelId: modelId,
      six_two_six: six_two_six,
    };
    console.log(bodyData);
    // Upload files to API
    await props.apiHelper
      .createModelJob(bodyData)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Rendering request has been sent successfully");
          // find status name from list of status
          //let newStatus = listOfRender.find((status) => status.six_two_six == statusSix_two_six);
          //props.refreshModel(newStatus.id, newStatus.statusName);
          let newStatus = 3;
          props.refreshModel(newStatus, "Render Requested");
          //disableButton();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // UI update status
  };

  return (
    <div id="shaders">
      <ListGroup className="list-group-flush">
        {/* <ListGroup.Item>
          <select
            className="ui dropdown"
            onChange={(e) => handleSelectInput(e)}
          >
            {listOfRender.map((option) => (
              <option key={option.six_two_six} value={option.six_two_six}>
                {option["six_two_six"]}
              </option>
            ))}
          </select>
        </ListGroup.Item> */}
        <ListGroup.Item>
          <button
            className="ui tiny button"
            //disabled={props.model.assetStatusId !== 2 ? true : false}
            disabled={isButtonDisabled}
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you wish to render for ${props.model.rangeName} - ${props.model.sizeDescription}?`
                )
              )
                submitSixTwoSix();
            }}
          >
            Send for render
          </button>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
});

export default ModelRenderButton;
