import React from "react";
import { createRoot } from "react-dom/client";

import Reducer from "./reducers/Reducer";
import { createStore } from "redux";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import ApiHelper from "./Helpers/api";

const pluginSettings = window.pluginSettings;

ApiHelper.init(pluginSettings.apiURL);

const store = createStore(Reducer);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <React.Fragment>
      <App apiHelper={ApiHelper} />
    </React.Fragment>
  </Provider>
);
