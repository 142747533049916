import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const AssetStatus = () => {
  return (
    <section className="py-5 text-center container">
      Texture Status
      <div>
        <Container fluid>
          <Row allign="center">
            <Col xs={4} md={5}>
              <Row>
                <Button className="bg-warning">Missing from S3</Button>
              </Row>
              <Row>
                <Button className="bg-secondary">Select Shader</Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-info">Ready for Asset Render</Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-danger text-white">
                  Render Reqeusted
                </Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-dark">Render Failed</Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-success">Render Completed</Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-primary">Asset Approved</Button>{" "}
              </Row>
              <Row></Row>
              <Row>
                <Button className="bg-info">Buyer Approval</Button>{" "}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default AssetStatus;
