import React from "react";
import RenderHeader from "../Components/render/RenderHeader";
import RenderFile from "../Components/render/RenderFile";

const Render = (props) => {
  return (
    <div>
      <RenderHeader />
      <RenderFile apiHelper={props.apiHelper}></RenderFile>
    </div>
  );
};

export default Render;
