import { React } from "react";
import InfoHeader from "../Components/info/InfoHeader";

const Info = (props) => {
  return (
    <div>
      <div className="ui container">
        <InfoHeader />
        <div>
          <a href="valid-shader-page">
            Valid shaders to use for embedding in a model
          </a>
        </div>
      </div>
    </div>
  );
};

export default Info;
