import { React, useEffect } from "react";
import ModelHeader from "../Components/modelVariant/ModelHeader";
import ModelList from "../Components/modelVariant/ModelList";

import { useDispatch } from "react-redux";

const ModelVariant = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncFn = async () => {
      let modelVariantData = await props.apiHelper.getModelVariantData();

      dispatch({
        type: "SET_MODEL_VARIANT_DATA",
        modelVariantData: modelVariantData.data,
        isLoading: false,
      });
    };

    const asyncFnModelStatus = async () => {
      let modelStatusData = await props.apiHelper.getModelStatusData();

      dispatch({
        type: "SET_MODEL_STATUS_DATA",
        modelStatusData: modelStatusData.data,
        isLoading: false,
      });
    };

    asyncFn();
    asyncFnModelStatus();
  }, []);

  return (
    <div>
      <div className="ui container">
        <ModelHeader />
        <ModelList apiHelper={props.apiHelper}></ModelList>
      </div>
    </div>
  );
};

export default ModelVariant;
