import React, { useState, useEffect } from "react";
import TextureCard from "./TextureCard";
import { useSelector } from "react-redux";
//import textureList from "../data.json";
import Container from "react-bootstrap/Container";

import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const TextureSidebar = (props) => {
  // from json
  // const listOftexture = textureList;
  const [textureStatus, setTextureStatus] = useState(0);

  const dispatch = useDispatch();

  // from database
  const listOfTexture = useSelector((state) => state.textureData);
  const listOfTextureStatus = useSelector((state) => state.textureStatusData);
  const handleTextureStatusFilterChange = (e) => {
    //alert(e.target.value);
    setTextureStatus(e.target.value);
    //asyncFnStatus();
    // alert(`Sidebar textureStatus ${e.target.value}`);
    props.refreshAssetStatus(e.target.value);
  };

  // useEffect(() => {
  //   const asyncFnStatus = async () => {
  //     //let statusData = await props.apiHelper.getStatusData();

  //     dispatch({
  //       type: "SET_STATUS",
  //       assetStatus: textureStatus,
  //       isLoading: false,
  //     });
  //   };
  //   alert(textureStatus);
  //   asyncFnStatus();
  // }, []);

  // const filteredTexture =
  //   textureStatus === 0
  //     ? listOfTexture
  //     : listOfTexture.filter(
  //         (texture) => texture.assetStatusId == textureStatus
  //       );

  // console.log(`textureStatus = ${textureStatus}`);
  // console.log(`filteredTexture.length = ${filteredTexture.length}`);
  // console.log(`listOfTextureStatus.length = ${listOfTextureStatus.length}`);

  //const renderTextureList = filteredTexture.map((texture) => {
  return (
    <Form className="sticky-top">
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={5}></Form.Label>
        <Form.Label column sm={2}>
          Texture Status:
        </Form.Label>
        <Col sm={3}>
          {/* <Form.Control type="email" placeholder="Email" /> */}
          <select
            className="ui dropdown"
            onChange={(e) => handleTextureStatusFilterChange(e)}
          >
            <option key="0" value="0">
              All
            </option>
            {listOfTextureStatus.map((option) => (
              <option key={option.id} value={option.id}>
                {option["statusName"]}
              </option>
            ))}
          </select>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default TextureSidebar;
