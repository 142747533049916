import React, { useState, useRef } from "react";
import footImage from "../../images/foot_v1.png";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// added redux
import { useSelector, useDispatch } from "react-redux";

const FootCard = (props) => {
  const { id, footType, footColour, footStatus, assetStatusId } = props.foot;

  //const LableStatus = footStatusId === 0 ? "Missing" : "Uploaded";
  const statusColour = assetStatusId === 1 ? "bg-warning" : "bg-info";
  const [message, setMessage] = useState(footStatus);
  const [buttonColour, setButtonColour] = useState(statusColour);
  //choose file reference, reset the value
  const inputRef = useRef(null);

  //--------------------------------------------------------

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState([]);

  // redux isLoading, footData var
  const isLoading = useSelector((state) => state.isLoading);
  let footData = useSelector((state) => state.footData);

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const disableButton = () => {
    setButtonDisabled(true);
    // alert("Button has been disabled!");
  };

  const enableButton = () => {
    setButtonDisabled(false);
    //    alert("Button has been enabled!");
  };

  const handleFileInput = (e, id, footType, footColour) => {
    setSelectedFile(e.target.files);
    // console.log(e.target.files.length);

    // for (let i = 0; i < e.target.files.length; i++) {
    //   alert(e.target.files[i].name);
    // }

    enableButton();
    alert("footType: " + footType + " & footColour: " + footColour);
  };

  const uploadFootFile = async (files) => {
    for (let i = 0; i < files.length; i++) {
      alert(files[i].name);
    }

    // Define metadata object
    let metadata = {
      id: id,
      footType: footType,
      footColour: footColour,
    };

    // Dispatch action to set loading state and set new FootDate with exists_on_s3 set
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    // Upload files to API
    await props.apiHelper.uploadFootFile(files, metadata);

    // find foot in big list and set it to uploaded
    let newFootData = footData.map((foot) => {
      if (foot.id === id) {
        foot.assetStatusId = 2;
        foot.footStatus = "Ready for Asset Render";
      }
      return foot;
    });
    console.log("newFootData");
    console.log(newFootData);
    // Dispatch action to reset loading state and update current upload to exists
    dispatch({
      type: "SET_FOOT_DATA",
      footData: newFootData,
      isLoading: false,
    });

    // set the current foot asset values
    // setMessageId(2);
    setMessage("Ready for Asset Render");
    setButtonColour("bg-info");
    disableButton();
    // setSelectedFile([]);
    inputRef.current.value = null;
  };

  //-----------------------------------------------------------

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Header>Next</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={4} md={5}>
            <Image src={footImage} rounded />
          </Col>
          <Col xs={6} md={7}>
            <Card.Title>{footType}</Card.Title>
            <Card.Text style={{ width: "10rem" }}>{footColour} </Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <ListGroup className="list-group-flush">
        {/* <ListGroup.Item>Cras justo odio</ListGroup.Item> */}
        <ListGroup.Item>
          {" "}
          <input
            ref={inputRef}
            id="actual-btn"
            type="file"
            multiple
            onChange={(e) => handleFileInput(e, id, footType, footColour)}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          {" "}
          <button
            id={id}
            disabled={isButtonDisabled}
            onClick={() => uploadFootFile(selectedFile)}
          >
            {" "}
            Upload to S3
          </button>
        </ListGroup.Item>
      </ListGroup>
      <Card.Footer className={"text-center " + buttonColour}>
        {message}
      </Card.Footer>
    </Card>
  );
};

export default FootCard;
