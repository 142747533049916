import React, { useState } from "react";
//import shaderList from "../../shader_data.json";
import { useSelector } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";

import Button from "react-bootstrap/Button";

const TextureShaderButton = (props) => {
  // const { materialType, fabricName, colour, shaderName, id } = props.texture;
  // buton diable and enable
  const [isSelectButtonDisabled, setSelectButtonDisabled] = useState(
    props.assetStatusId !== 9 ? true : false
  );

  const { id } = props.texture;
  // from json
  //const listOfShader = shaderList;

  // from database
  // redux isLoading, listOfShader var
  let listOfShader = useSelector((state) => state.shaderData);
  let listOfTextureStatus = useSelector((state) => state.textureStatusData);

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [textureId, setTextureId] = useState();
  const [shaderId, setShaderId] = useState();

  const [statusId, setStatusId] = useState(2);

  //const [shaderName, setShaderName] = useState(props.texture.shaderName);
  //console.log(shaderName);

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const handleSelectInput = (e) => {
    setTextureId(id);
    setShaderId(e.target.value);
    //setShaderName(e.target.name);

    //alert("id = " + id);
    // alert("props.texture.id = " + props.texture.id);

    //alert(e.target.value);

    if (e.target.value === 0) {
      disableButton();
    } else {
      enableButton();
    }
    // alert(props.texture.id);
    // alert(shaderId);

    //props.newMessage("Ready to render");
    //props.newMessage(shaderId);
  };

  const approveShader = async (e) => {
    // Define metadata object
    // setShaderId(e);
    setTextureId(props.texture.id);
    //alert(shaderId);
    let bodyData = {
      textureId: textureId,
      shaderId: shaderId,
    };
    console.log(bodyData);
    // Upload files to API
    await props.apiHelper
      .approveShaderTexture(bodyData)
      .then((response) => {
        console.log(`Shader updated: (response.status) ${response.status}`);
        // console.log(response.data);
        // Handle data
        if (response.status === 200) {
          //   window.location.reload(false);
          console.log(response.state);
          console.log(`shaderId = ${shaderId}`);
          // find status name from list of status
          let newStatus = listOfTextureStatus.find(
            (status) => status.id == statusId
          );
          console.log(`refreshTexture shaderId = ${shaderId}`);
          //console.log(listOfShader);
          let selectedShader = listOfShader.find(
            (shader) => shader.id == shaderId
          );

          console.log(selectedShader);

          //          alert(selectedShader.shaderName + " and " + newStatus.statusName);
          props.refreshTexture(
            newStatus.id,
            newStatus.statusName,
            selectedShader.shaderName
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ListGroup className="list-group-flush">
      <ListGroup.Item>
        <select
          // disabled={isSelectButtonDisabled}
          className="ui dropdown"
          onChange={(e) => handleSelectInput(e)}
        >
          {" "}
          <option value={0}>Select shader</option>
          {listOfShader.map((option) => (
            <option key={option.id} value={option.id}>
              {option["shaderName"]}
            </option>
          ))}
        </select>
      </ListGroup.Item>
      <ListGroup.Item>
        <Button
          className="btn-info"
          disabled={isButtonDisabled}
          onClick={() => approveShader()}
        >
          Submit shader
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
  return (
    // <div id="shaders">
    //   <ListGroup className="list-group-flush">
    //     <ListGroup.Item> {shaderName}</ListGroup.Item>
    //     <ListGroup.Item>
    //       <button className="ui tiny  button" disabled={isButtonDisabled}>
    //         Render File
    //       </button>
    //       <button
    //         className="ui tiny  button"
    //         disabled={isButtonDisabled}
    //         onClick={() => approveShader()}
    //       >
    //         Approve
    //       </button>
    //     </ListGroup.Item>
    //   </ListGroup>
    // </div>
    <div id="shaders">
      {/* <ListGroup className="list-group-flush">
        <ListGroup.Item> {shaderName}</ListGroup.Item>
        <ListGroup.Item>
          <select
            className="ui dropdown"
            onChange={(e) => handleSelectInput(e)}
          >
            {" "}
            <option value={0}>Select shader</option>
            {listOfShader.map((option) => (
              <option key={option.id} value={option.id}>
                {option["shaderName"]}
              </option>
            ))}
          </select>
        </ListGroup.Item>
        <ListGroup.Item>
          <button className="ui tiny button" disabled={isButtonDisabled}>
            Render File
          </button>
          <button
            className="ui tiny button"
            disabled={isButtonDisabled}
            onClick={() => approveShader()}
          >
            Approve
          </button>
        </ListGroup.Item>
      </ListGroup> */}
    </div>
  );
};
//};

export default TextureShaderButton;
