import { React, useState } from "react";
import PropTypes from "prop-types";
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";

import LoadingOverlay from "react-loading-overlay";
import useWebSocket from "react-use-websocket";
import { useStopwatch } from "react-timer-hook";

import "../../App.css";

import api from "../../Helpers/api";

// your app imports here
window.Buffer = window.Buffer || require("buffer").Buffer;

LoadingOverlay.propTypes = undefined;
Amplify.configure(awsconfig);

const RenderFile = (props) => {
  //function RenderFile(props) {
  const [loading, setLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [image, setImage] = useState();
  const [timestamp, setTimestamp] = useState();

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const socketUrl = window.pluginSettings.websocket;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("connection opened");
    },
    share: true,
    onMessage: (message) => {
      let data = JSON.parse(message.data);
      if (data.message == `done${timestamp}`) {
        pause();
        setLoading(false);
        setImage(data.image);
      }
    },
    onClose: (res) => {
      console.log(res);
      console.log("connection closed");
    },

    shouldReconnect: (closeEvent) => true,
  });

  const buttonClick = () => {
    if (firstRun) {
      setFirstRun(false);
    }
    reset();
    start();
    setLoading(true);
    setImage();
    let ts = new Date().valueOf();
    setTimestamp(ts);
    console.log({ ts });
    props.apiHelper.startRender(ts);
  };

  return (
    <LoadingOverlay
      fadeSpeed={100}
      spinner
      active={loading}
      text="Please wait for render to complete...."
      styles={{
        wrapper: (base) => ({
          ...base,
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "auto",
          zIndex: 1500,
        }),
        overlay: (base) => ({
          ...base,
          zIndex: 1500,
        }),
      }}
    >
      <div className="RanderFile">
        <div style={styles.container}></div>

        <div>
          <button disabled={isRunning} onClick={buttonClick.bind(this)}>
            START RENDER
          </button>
          {!firstRun ? (
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "100px" }}>
                <span>
                  {minutes.toString().length === 1 ? "0" : ""}
                  {minutes}
                </span>
                :
                <span>
                  {seconds.toString().length === 1 ? "0" : ""}
                  {seconds}
                </span>
              </div>
            </div>
          ) : null}
          {image ? (
            <div>
              <img src={`data:image/png;base64,${image}`} />
            </div>
          ) : null}
        </div>
      </div>
    </LoadingOverlay>
  );
};

const styles = {
  container: {
    width: 900,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
};

// RenderFile.propTypes = {
//   signOut: PropTypes.func,
//   user: PropTypes.object
// }

//export default withAuthenticator(RenderFile);

export default RenderFile;
