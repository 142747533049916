import React, { useState, useRef } from "react";

// added redux
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const SelctModelFileButton = (props) => {
  const {
    id,
    rangeName,
    sizeDescription,
    widthCm,
    heightCm,
    depthCm,
    has_been_uploaded,
  } = props.model;

  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);
  //const [range, setRange] = useState();
  //const [colour, setColour] = useState();

  //const handleFileInput = (e, colour) => {
  // redux isLoading var
  const isLoading = useSelector((state) => state.isLoading);
  let modelVariantData = useSelector((state) => state.modelVariantData);
  let listOfStatus = useSelector((state) => state.statusData);
  //choose file reference, reset the value
  const inputRef = useRef(null);

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const disableButton = () => {
    setButtonDisabled(true);
    // alert("Button has been disabled!");
  };

  const enableButton = () => {
    setButtonDisabled(false);
    //    alert("Button has been enabled!");
  };

  const handleFileInput = (e, id, rangeName, sizeDescription) => {
    setSelectedFile(e.target.files);
    enableButton();
    // setRange(range);
    // setColour(colour);
    alert("rangeName: " + rangeName + " & sizeDescription: " + sizeDescription);
  };

  const uploadModelFile = async (files) => {
    // Define metadata object
    let metadata = {
      id: id,
      rangeName: rangeName,
      sizeDescription: sizeDescription,
      resolution: "2048 x 2048",
      size: "25cm, 0.25m",
    };

    // Dispatch action to set loading state and set new ModelDate with exists_on_s3 set
    // dispatch({
    //   type: "SET_IS_LOADING",
    //   isLoading: true,
    // });
    // Upload files to API
    await props.apiHelper
      .uploadModelFile(files, metadata)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Model files uploded successfully");
          // find status name from list of status
          let newStatus = listOfStatus.find((status) => status.id == 2);
          props.refreshModel(newStatus.id, newStatus.statusName);
          disableButton();
          inputRef.current.value = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // find model in big list and set it to uploaded
    let newModelVariantData = modelVariantData.map((model) => {
      if (model.id === id) {
        model.assetStatusId = 2;
      }
      return model;
    });

    // Dispatch action to reset loading state and update current upload to exists
    dispatch({
      type: "SET_MODEL_VARIANT_DATA",
      modelVariantData: newModelVariantData,
      isLoading: false,
    });
  };

  return (
    <div>
      <input
        ref={inputRef}
        id="actual-btn"
        type="file"
        multiple
        onChange={(e) => handleFileInput(e, id, rangeName, sizeDescription)}
      />
      <button
        id={id}
        disabled={isButtonDisabled}
        onClick={() => uploadModelFile(selectedFile)}
      >
        {" "}
        Upload to S3
      </button>
    </div>
  );
};

export default SelctModelFileButton;
