import React from "react";
//import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { signOut, getCurrentUser } from "aws-amplify/auth";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavbarC from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Navbar = () => {
  const signOutHandler = async () => {
    try {
      await signOut();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <NavbarC bg="primary" data-bs-theme="dark" className="sticky-top">
        <Container>
          <NavbarC.Brand href="#home">iGen7</NavbarC.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/texture-page">Texture</Nav.Link>
            <Nav.Link href="/foot-page">Foot</Nav.Link>
            <Nav.Link href="/info-page">Information</Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Button
              className="btn btn-danger"
              type="button"
              onClick={signOutHandler}
            >
              Sign Out
            </Button>
          </Form>
        </Container>
      </NavbarC>
    </>
  );
};

export default Navbar;
