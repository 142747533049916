import React, { useRef, useState } from "react";
import modelImage from "../../images/model_v1.png";
import ModelUploadButton from "./ModelUploadButton";
import ModelStatusButton from "./ModelStatusButton";
import ModelRenderButton from "./ModelRenderButton";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Image slider
import Lightbox from "yet-another-react-lightbox";
// thumbnails
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// captions
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import Spinner from "react-bootstrap/Spinner";

const ModelCard = (props) => {
  const {
    id,
    rangeName,
    sizeDescription,
    widthCm,
    heightCm,
    depthCm,
    assetStatusId,
    modelStatus,
  } = props.model;
  //const modelStatus = has_been_uploaded === 0 ? "Missing" : "Uploaded";
  const [message, setMessage] = useState(modelStatus);
  const [data, setData] = useState([]);
  const ref = useRef();
  const renderRef = useRef();

  // Image slider
  const [open, setOpen] = React.useState(false);
  // Thumbnails ref usage example
  const thumbnailsRef = React.useRef(null);

  const newMessage = (myMessage) => {
    // alert(myMessage);
    setMessage(myMessage);
  };

  const renderColorSwitch = (assetStatusId) => {
    switch (assetStatusId) {
      case 1: //Missing from S3
        return "bg-warning";
      case 9: // Select Shader
        return "bg-secondary";
      case 2: // Ready for asset render
        return "bg-info";
      case 3: // Render Requested
        return "bg-danger text-white";
      case 4: // Render Failed
        return "bg-dark";
      case 5: // Render Completed
        return "bg-success";
      case 6: // Asset Approved
        return "bg-primary text-white";
      case 7: // Techinical Approval
        return "bg-primary";
      case 8: // Buyer Approval
        return "bg-info";
      default:
        return "bg-info";
    }
  };
  const [buttonColour, setButtonColour] = useState(
    renderColorSwitch(assetStatusId)
  );

  const modelSpinner = () => {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  };

  const handleModelData = (newStatusId, newStatusName) => {
    setButtonColour(renderColorSwitch(newStatusId));
    setMessage(newStatusName);
    ref.current?.handleUploadButton(newStatusId);
    renderRef.current?.handleRenderButton(newStatusId);
  };

  const getListOfIamges = async (modelId) => {
    //alert("modelId : " + modelId);
    setOpen(true);
    // hardcoded for testing
    //modelId = "7b8a1d08af35420c37ae1d8375b13bd7";

    let modelOutput = await props.apiHelper.getModelOutputData(modelId);

    let i = 1;
    let data1 = modelOutput.data.map((x) => {
      //   console.log({ x });
      let obj = {
        src: x.cloudFrontUrl,
        title: x.objectName,
        description: i++ + "\n" + x.requestedDate + "\n" + x.cameraName,
      };
      return obj;
    });
    setData(data1);
  };

  return (
    <Card style={{ width: "18rem", borderWidth: "thick" }}>
      <Card.Header>Next</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={4} md={5}>
            <Image src={modelImage} rounded />
          </Col>
          {/* <Col xs={4} md={5} style={{ padding: "5px" }}>
            <a target="_blank" href="img_forest.jpg">
              <img
                src="https://output-on-demand-models-preview.nonprod.gen7.cloud/Vanilla_15_63_3%23d23a1a07-8c56-47d8-9478-c59f91c89b5a%23Bamburgh%20by%20Jasper%20Conran%23Studio%20Chenille%23%20Oyster%23C3_11%23A0%23Gfalse%23metal_nappedmetal_b_v01.png"
                alt="Forest"
              />
            </a>
          </Col> */}
          <Col xs={6} md={7}>
            <Card.Title>{rangeName}</Card.Title>
            <Card.Text style={{ width: "10rem" }}>{sizeDescription} </Card.Text>
            <Card.Text style={{ width: "10rem" }}>
              {" "}
              {widthCm}x {heightCm} x {depthCm}
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>

      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <button type="button" onClick={() => getListOfIamges(id)}>
            Output files
          </button>
          <Lightbox
            plugins={[Thumbnails, Captions]}
            open={open}
            close={() => setOpen(false)}
            slides={data}
            thumbnails={{ ref: thumbnailsRef }}
            on={{
              click: () => {
                (thumbnailsRef.current?.visible
                  ? thumbnailsRef.current?.hide
                  : thumbnailsRef.current?.show)?.();
              },
            }}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <ModelUploadButton
            ref={ref}
            refreshModel={handleModelData}
            model={props.model}
            apiHelper={props.apiHelper}
            assetStatusId={assetStatusId}
          />

          <ModelStatusButton
            refreshModel={handleModelData}
            model={props.model}
            key={props.model.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
          ></ModelStatusButton>
        </ListGroup.Item>
        <ListGroup.Item>
          <ModelRenderButton
            ref={renderRef}
            refreshModel={handleModelData}
            model={props.model}
            key={props.model.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
            assetStatusId={assetStatusId}
          ></ModelRenderButton>
        </ListGroup.Item>
      </ListGroup>
      <Card.Footer className={"text-center " + buttonColour}>
        {message}
      </Card.Footer>
    </Card>
  );
};

export default ModelCard;
