import React from "react";
import ModelCard from "./ModelCard";
import { useSelector } from "react-redux";
//import modelList from "./model_data.json";

const ModelList = (props) => {
  // from json
  //const listOfModel = modelList;

  // from database
  const listOfModel = useSelector((state) => state.modelVariantData);
  // console.log("listOfModel.length = " + listOfModel.length);
  const renderModelList = listOfModel.map((model) => {
    return (
      <ModelCard key={model.id} model={model} apiHelper={props.apiHelper} />
    );
  });

  return <div className="ui four cards">{renderModelList}</div>;
};

export default ModelList;
