import { React, useState, useEffect } from "react";
import ModelHeader from "../Components/model/ModelHeader";
import ModelList from "../Components/model/ModelList";

import { useDispatch } from "react-redux";

const Model = (props) => {
  const [assetStatus, setAssetStatus] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    const asyncFn = async () => {
      let modelData = await props.apiHelper.getModelData();

      dispatch({
        type: "SET_MODEL_DATA",
        modelData: modelData.data,
        isLoading: false,
      });
    };

    const asyncFnModelStatus = async () => {
      let modelStatusData = await props.apiHelper.getModelStatusData();

      dispatch({
        type: "SET_MODEL_STATUS_DATA",
        modelStatusData: modelStatusData.data,
        isLoading: false,
      });
    };

    const asyncFnTextureStatus = async () => {
      let textureStatusData = await props.apiHelper.getTextureStatusData();

      dispatch({
        type: "SET_TEXTURE_STATUS_DATA",
        textureStatusData: textureStatusData.data,
        isLoading: false,
      });
    };

    asyncFn();
    asyncFnModelStatus();
    // asyncFnTextureStatus();
  }, []);

  return (
    <div class="container-fluid">
      <ModelHeader />
      <ModelList
        apiHelper={props.apiHelper}
        assetStatus={assetStatus}
      ></ModelList>
    </div>
  );
};

export default Model;
