import React, { useState } from "react";
import cameraList from "./camera_data_v2.json";

// web socket import start
import LoadingOverlay from "react-loading-overlay";
import useWebSocket from "react-use-websocket";
import { useStopwatch } from "react-timer-hook";
// web socket import end

const CameraListButton = (props) => {
  const listOfCamera = cameraList;

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const handleSelectInput = (e) => {
    enableButton();
    props.newMessage("Ready to render");
  };

  // Web socket start

  const [loading, setLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [image, setImage] = useState();
  const [timestamp, setTimestamp] = useState();

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const socketUrl = window.pluginSettings.websocket;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("connection opened");
    },
    share: true,
    onMessage: (message) => {
      let data = JSON.parse(message.data);
      if (data.message == `done${timestamp}`) {
        pause();
        setLoading(false);
        setImage(data.image);
      }
    },
    onClose: (res) => {
      console.log(res);
      console.log("connection closed");
    },

    shouldReconnect: (closeEvent) => true,
  });

  const buttonClick = () => {
    if (firstRun) {
      setFirstRun(false);
    }
    reset();
    start();
    setLoading(true);
    setImage();
    let ts = new Date().valueOf();
    setTimestamp(ts);
    console.log({ ts });
    props.apiHelper.startRender(ts);
  };
  // Web scoket end

  return (
    <LoadingOverlay
      fadeSpeed={100}
      spinner
      active={loading}
      text="Please wait for render to complete...."
      styles={{
        wrapper: (base) => ({
          ...base,
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "auto",
          zIndex: 1500,
        }),
        overlay: (base) => ({
          ...base,
          zIndex: 1500,
        }),
      }}
    >
      <div id="shaders">
        <div className="ui input">
          <select
            className="ui dropdown"
            onChange={(e) => handleSelectInput(e)}
          >
            {listOfCamera.map((option) => (
              <option value={option.Camera}>{option["Camera"]}</option>
            ))}
          </select>
        </div>

        <button
          className="ui tiny  button"
          disabled={isButtonDisabled}
          onClick={buttonClick.bind(this)}
        >
          Render File
        </button>
      </div>
    </LoadingOverlay>
  );
};

export default CameraListButton;
