import React, { useState } from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

const ValidShaderList = (props) => {
  // from database
  const listOfValidShader = useSelector((state) => state.validShaderData);
  console.log(`listOfValidShader.length = ${listOfValidShader.length}`);

  return (
    <Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Typical group</th>
            <th>Shader Group Name</th>
            <th>Shader Name</th>
          </tr>
        </thead>
        <tbody>
          {listOfValidShader.map((shader) => {
            return (
              <tr>
                <td>{shader.shaderGroupType}</td>
                <td>{shader.shaderGroupName}</td>
                <td id={shader.id}>{shader.shaderName}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default ValidShaderList;
