import React from "react";
import Button from "react-bootstrap/Button";

const HomeBanner = () => {
  return (
    <section className="py-5 text-center container">
      <div className="row py-lg-5">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h1 className="fw-light">iGen7 Asset Management</h1>
          <hr></hr>
          <p className="lead text-body-secondary"></p>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
