import React from "react";

const FootHeader = () => {
  return (
    <div className="ui container">
      <br></br>
      <h2 className="ui header">Foot page</h2>
      <br></br>
    </div>
  );
};

export default FootHeader;
