import React, { useRef, useState } from "react";
import textureImage from "../../images/image_u19-7.svg";
import TextureUploadButton from "./TextureUploadButton";

import TextureOutputButton from "./TextureOutputButton";
import TextureShaderButton from "./TextureShaderButton";
import TextureStatusButton from "./TextureStatusButton";
import TextureRenderButton from "./TextureRenderButton";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";

const TextureCard = (props) => {
  const {
    id,
    materialType,
    fabricName,
    colour,
    textureStatus,
    assetStatusId,
    shaderName,
  } = props.texture;

  // let listOfShader = useSelector((state) => state.shaderData);
  //console.log(`listOfShader.length ${listOfShader.length}`);
  const textRef = useRef();
  const renderRef = useRef();

  const [message, setMessage] = useState(textureStatus);
  const [shaderNameText, setShaderNameText] = useState(shaderName);
  if (shaderNameText === undefined || shaderNameText === null) {
    setShaderNameText("No Shader");
  }
  const newMessage = (myMessage) => {
    setMessage(myMessage);
  };
  const renderColorSwitch = (assetStatusId) => {
    switch (assetStatusId) {
      case 1: //Missing from S3
        return "bg-warning";
      case 9: // Select Shader
        return "bg-secondary";
      case 2: // Ready for asset render
        return "bg-info";
      case 3: // Render Requested
        return "bg-danger text-white";
      case 4: // Render Failed
        return "bg-dark";
      case 5: // Render Completed
        return "bg-success";
      case 6: // Asset Approved
        return "bg-primary text-white";
      case 7: // Techinical Approval
        return "bg-primary";
      case 8: // Buyer Approval
        return "bg-info";
      default:
        return "bg-info";
    }
  };

  const [buttonColour, setButtonColour] = useState(
    renderColorSwitch(assetStatusId)
  );

  const handleTextureData = (newStatusId, newStatusName, shaderName) => {
    // alert(newStatusId + " and " + newStatusName);

    setButtonColour(renderColorSwitch(newStatusId));
    setMessage(newStatusName);
    if (shaderName) {
      setShaderNameText(shaderName);
    }
    // console.log(textRef);
    // console.log(Object.getOwnPropertyNames(textRef.current));
    // call child component
    // alert(`handleUploadTextButton`);
    textRef.current?.handleUploadTextButton(newStatusId);
  };

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Header>Next</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={4} md={5}>
            <Image src={textureImage} rounded />
          </Col>
          <Col xs={6} md={7}>
            <Card.Title>{fabricName}</Card.Title>
            <Card.Text style={{ width: "10rem" }}>{colour} </Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <TextureOutputButton
            texture={props.texture}
            apiHelper={props.apiHelper}
          />
        </ListGroup.Item>
      </ListGroup>

      <ListGroup className="list-group-flush">
        <ListGroup.Item className="text-center list-group-item-light">
          {" "}
          {shaderNameText}
        </ListGroup.Item>
        <ListGroup.Item>
          <TextureUploadButton
            ref={textRef}
            refreshModel={handleTextureData}
            texture={props.texture}
            apiHelper={props.apiHelper}
            assetStatusId={assetStatusId}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <TextureShaderButton
            texture={props.texture}
            key={props.texture.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
            refreshTexture={handleTextureData}
            assetStatusId={assetStatusId}
          ></TextureShaderButton>
        </ListGroup.Item>
        <ListGroup.Item>
          <TextureStatusButton
            refreshTexture={handleTextureData}
            texture={props.texture}
            key={props.texture.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
          ></TextureStatusButton>
        </ListGroup.Item>
        <ListGroup.Item>
          <TextureRenderButton
            ref={renderRef}
            refreshTexture={handleTextureData}
            texture={props.texture}
            key={props.texture.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
            assetStatusId={assetStatusId}
          ></TextureRenderButton>
        </ListGroup.Item>
      </ListGroup>
      <Card.Footer className={"text-center " + buttonColour}>
        {message}
      </Card.Footer>
    </Card>
  );
};

export default TextureCard;
