import React, { useState } from "react";
import ModelCard from "./ModelCard";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

//import modelList from "./model_data.json";

const ModelList = (props) => {
  // from json
  // const listOfModel = modelList;
  const [modelStatus, setModelStatus] = useState(0);

  // from database
  const listOfModel = useSelector((state) => state.modelData);
  const listOfModelStatus = useSelector((state) => state.modelStatusData);

  const handleModelStatusFilterChange = (e) => {
    //alert(e.target.value);
    setModelStatus(e.target.value);
  };

  console.log(`modelStatus = ${modelStatus}`);
  console.log(`listOfModel.length = ${listOfModel.length}`);
  // console.log(`listOfModelStatus.length = ${listOfModelStatus.length}`);

  const filteredModels =
    modelStatus === 0
      ? listOfModel
      : listOfModel.filter((model) => model.assetStatusId == modelStatus);

  console.log(`modelStatus = ${modelStatus}`);
  console.log(`filteredModels.length = ${filteredModels.length}`);

  return (
    <Container>
      <div className="ui four cards">
        <div>
          Model Status
          <select
            className="ui dropdown"
            onChange={(e) => handleModelStatusFilterChange(e)}
          >
            <option key="0" value="0">
              All
            </option>
            {listOfModelStatus.map((option) => (
              <option key={option.id} value={option.id}>
                {option["statusName"]}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="ui four cards">
        {filteredModels.map((model) => {
          return (
            <ModelCard
              key={model.id}
              model={model}
              apiHelper={props.apiHelper}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default ModelList;
