import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";

// added redux
import { useSelector, useDispatch } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";

const TextureUploadButton = forwardRef((props, ref) => {
  const { fabricName, colour, id } = props.texture;

  const dispatch = useDispatch();

  //const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);
  //const [buttonColour, setButtonColour] = useState("bg-info");

  //const [range, setRange] = useState();
  //const [colour, setColour] = useState();

  //const handleFileInput = (e, colour) => {
  // redux isLoading var
  //const isLoading = useSelector((state) => state.isLoading);
  let textureData = useSelector((state) => state.textureData);
  let listOfTextureStatus = useSelector((state) => state.textureStatusData);
  const inputRef = useRef(null);

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(
    props.assetStatusId === 1 ? false : true
  );
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  const enableUploadToS3Button = () => {
    setUploadButtonDisabled(false);
  };

  const disableUploadToS3Button = () => {
    setUploadButtonDisabled(true);
  };

  function handleUploadTextButton(newAssetStatusId) {
    if (newAssetStatusId === 1) {
      enableButton();
      enableUploadToS3Button();
    } else {
      disableButton();
      disableUploadToS3Button();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      handleUploadTextButton,
    };
  });

  const handleFileInput = (e, id, range, colour) => {
    setSelectedFile(e.target.files);
    enableUploadToS3Button();
    alert(`Selected file for ${fabricName} with colour of ${colour}`);
  };

  const uploadFile = async (files) => {
    alert(`No of file selected: ${files.length}`);
    // Define metadata object
    let metadata = {
      id: id,
      fabricName: fabricName,
      colour: colour,
      resolution: "2048 x 2048",
      size: "25cm, 0.25m",
    };

    // Dispatch action to set loading state and set new TextureDate with exists_on_s3 set
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    // Upload files to API
    //await props.apiHelper.uploadTextureFile(files, metadata);
    // Upload files to API
    await props.apiHelper
      .uploadTextureFile(files, metadata)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Texture files uploded successfully");
          // find status name from list of status
          let newStatus = listOfTextureStatus.find(
            (status) => Number(status.id) === 9
          );
          props.refreshModel(newStatus.id, newStatus.statusName);
          //disableButton();
          inputRef.current.value = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // find texture in big list and set it to uploaded
    let newTextureData = textureData.map((tex) => {
      if (tex.id === id) {
        tex.has_been_uploaded = 1;
      }
      return tex;
    });
    // Dispatch action to reset loading state and update current upload to exists
    dispatch({
      type: "SET_TEXTURE_DATA",
      textureData: newTextureData,
      isLoading: false,
    });
  };

  return (
    <ListGroup className="list-group-flush">
      <ListGroup.Item>
        {/* {props.assetStatusId} */}
        <input
          ref={inputRef}
          disabled={isButtonDisabled}
          id="actual-btn"
          type="file"
          multiple
          onChange={(e) => handleFileInput(e, id, fabricName, colour)}
        />
      </ListGroup.Item>

      <ListGroup.Item>
        <Button
          className="btn-info"
          id={id}
          disabled={isUploadButtonDisabled}
          onClick={() => uploadFile(selectedFile)}
        >
          {" "}
          Upload to S3
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
});

export default TextureUploadButton;
