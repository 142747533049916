const initState = {
  isLoading: false,
  textureData: [],
  modelData: [],
  modelVariantData: [],
  footData: [],
  shaderData: [],
  validShaderData: [],
  modelStatusData: [],
  textureStatusData: [],
  renderData: [],
  assetStatus: 0,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_TEXTURE_DATA":
      return {
        ...state,
        textureData: action.textureData,
        isLoading: action.isLoading,
      };
    case "SET_MODEL_DATA":
      return {
        ...state,
        modelData: action.modelData,
        isLoading: action.isLoading,
      };
    case "SET_MODEL_VARIANT_DATA":
      return {
        ...state,
        modelVariantData: action.modelVariantData,
        isLoading: action.isLoading,
      };
    case "SET_FOOT_DATA":
      return {
        ...state,
        footData: action.footData,
        isLoading: action.isLoading,
      };
    case "SET_SHADER_DATA":
      return {
        ...state,
        shaderData: action.shaderData,
        isLoading: action.isLoading,
      };
    case "SET_VALID_SHADER_DATA":
      return {
        ...state,
        validShaderData: action.validShaderData,
        isLoading: action.isLoading,
      };
    case "SET_MODEL_STATUS_DATA":
      return {
        ...state,
        modelStatusData: action.modelStatusData,
        isLoading: action.isLoading,
      };
    case "SET_TEXTURE_STATUS_DATA":
      return {
        ...state,
        textureStatusData: action.textureStatusData,
        isLoading: action.isLoading,
      };
    case "SET_STATUS":
      return {
        ...state,
        assetStatus: action.assetStatus,
        isLoading: action.isLoading,
      };
    case "SET_RENDER_DATA":
      return {
        ...state,
        renderData: action.renderData,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default Reducer;
