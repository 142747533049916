import React, { useState } from "react";
import modelImage from "../../images/model_v1.png";
import SelctModelFileButton from "./SelectModelFileButton";
import CameraListButton from "./CameraListButton";
import StatusListButton from "./StatusListButton";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Image slider
import Lightbox from "yet-another-react-lightbox";
// thumbnails
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// captions
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

const ModelCard = (props) => {
  const {
    id,
    rangeName,
    sizeDescription,
    widthCm,
    heightCm,
    depthCm,
    assetStatusId,
    modelStatus,
  } = props.model;
  //const modelStatus = has_been_uploaded === 0 ? "Missing" : "Uploaded";
  const [message, setMessage] = useState(modelStatus);
  const [data, setData] = useState([]);

  // Image slider
  const [open, setOpen] = React.useState(false);
  // Thumbnails ref usage example
  const thumbnailsRef = React.useRef(null);

  const newMessage = (myMessage) => {
    // alert(myMessage);
    setMessage(myMessage);
  };

  const renderColorSwitch = (assetStatusId) => {
    switch (assetStatusId) {
      case 1: //missing from S3
        return "bg-warning";
      case 2: // Ready for asset render
        return "bg-info";
      case 3: // Render has been requestd
        return "bg-info";
      case 4: // Render failed
        return "bg-danger";
      case 5: // Render completed
        return "bg-Primary";
      case 6: // Asset approved
        return "bg-success";
      case 7: // Techinocal Approval
        return "bg-secondary";
      case 8: // Buyer Approval
        return "bg-info";
      default:
        return "bg-info";
    }
  };
  const [buttonColour, setButtonColour] = useState(
    renderColorSwitch(assetStatusId)
  );

  const handleModelData = (newStatusId, newStatusName) => {
    setButtonColour(renderColorSwitch(newStatusId));
    setMessage(newStatusName);
  };

  const getListOfIamges = async (modelId) => {
    //alert("modelId : " + modelId);
    setOpen(true);
    // hardcoded for testing
    //modelId = "7b8a1d08af35420c37ae1d8375b13bd7";

    let modelOutput = await props.apiHelper.getModelOutputData(modelId);

    let i = 1;
    let data1 = modelOutput.data.map((x) => {
      //   console.log({ x });
      let obj = {
        src: x.cloudFrontUrl,
        title: x.objectName,
        description: i++ + "\n" + x.requestedDate + "\n" + x.cameraName,
      };
      return obj;
    });
    setData(data1);
  };

  // console.log({ data });

  return (
    <Card style={{ width: "18rem", borderWidth: "thick" }}>
      <Card.Header>Next</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={4} md={5}>
            <Image src={modelImage} title={id} rounded />
          </Col>
          <Col xs={6} md={7}>
            <Card.Title>{rangeName}</Card.Title>
            <Card.Text style={{ width: "10rem" }}>{sizeDescription} </Card.Text>
            <Card.Text style={{ width: "10rem" }}>
              {" "}
              {widthCm}x {heightCm} x {depthCm}
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>

      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <button type="button" onClick={() => getListOfIamges(id)}>
            Output files
          </button>
          <Lightbox
            plugins={[Thumbnails, Captions]}
            open={open}
            close={() => setOpen(false)}
            slides={data}
            thumbnails={{ ref: thumbnailsRef }}
            on={{
              click: () => {
                (thumbnailsRef.current?.visible
                  ? thumbnailsRef.current?.hide
                  : thumbnailsRef.current?.show)?.();
              },
            }}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <SelctModelFileButton
            refreshModel={handleModelData}
            model={props.model}
            apiHelper={props.apiHelper}
          />
          {/* <CameraListButton
            apiHelper={props.apiHelper}
            newMessage={newMessage}
          ></CameraListButton> */}
          <StatusListButton
            refreshModel={handleModelData}
            model={props.model}
            key={props.model.id}
            apiHelper={props.apiHelper}
            newMessage={newMessage}
          ></StatusListButton>
        </ListGroup.Item>
      </ListGroup>
      <Card.Footer className={"text-center " + buttonColour}>
        {message}
      </Card.Footer>
    </Card>

    // <div className="card" style={{ widthCm: "1.2m" }}>
    //   <div className="ui content" style={{ backgroundColor: "#AEAEAE" }}>
    //     <div className="header" style={{ color: "#FFFFFF" }}>
    //       {/* {has_been_uploaded === 0 ? "Missing" : "Uploaded"} */}
    //       Next
    //     </div>
    //   </div>
    //   <div className="content">
    //     <img className="left floated ui image" src={modelImage} alt="model" />

    //     {/* <div className="right">{materialType}</div> */}
    //     <div className="right">{rangeName}</div>
    //     <div className="right">{sizeDescription}</div>
    //     <div className="right">
    //       {widthCm} x {heightCm} x {depthCm}
    //     </div>
    //     {/* <div className="right">Large Square Angle - Light</div>
    //     <div>&nbsp;</div> */}
    //   </div>
    //   <div className="extra content">
    //     <SelctModelFileButton model={props.model} apiHelper={props.apiHelper} />
    //     <CameraListButton
    //       apiHelper={props.apiHelper}
    //       newMessage={newMessage}
    //     ></CameraListButton>
    //   </div>
    //   <button className="ui orange button"> {message}</button>
    // </div>
  );
};

export default ModelCard;
