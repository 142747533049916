import { React, useState, useEffect } from "react";
import ValidShaderHeader from "../Components/validShader/ValidShaderHeader";
import ValidShaderList from "../Components/validShader/ValidShaderList";

import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ValidShader = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncFnValidShader = async () => {
      let validShaderData = await props.apiHelper.getValidShaderData();

      dispatch({
        type: "SET_VALID_SHADER_DATA",
        validShaderData: validShaderData.data,
        isLoading: false,
      });
    };
    asyncFnValidShader();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={6}>
          <ValidShaderHeader />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ValidShaderList apiHelper={props.apiHelper}></ValidShaderList>
        </Col>
      </Row>
    </Container>
  );
};

export default ValidShader;
