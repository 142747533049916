import { React, useState, useEffect } from "react";
import TextureHeader from "../Components/texture/TextureHeader";
import TextureSidebar from "../Components/texture/TextureSidebar";
import TextureList from "../Components/texture/TextureList";

import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Texture = (props) => {
  const [assetStatus, setAssetStatus] = useState(0);

  const dispatch = useDispatch();

  const refreshAssetStatus = (newStatus) => {
    // alert(`Page ${newStatus}`);
    setAssetStatus(newStatus);
  };

  useEffect(() => {
    const asyncFn = async () => {
      let data = await props.apiHelper.getTextureData();

      dispatch({
        type: "SET_TEXTURE_DATA",
        textureData: data.data,
        isLoading: false,
      });
    };

    const asyncFnShader = async () => {
      let shaderData = await props.apiHelper.getShaderData();

      dispatch({
        type: "SET_SHADER_DATA",
        shaderData: shaderData.data,
        isLoading: false,
      });
    };

    const asyncFnTextureStatus = async () => {
      let textureStatusData = await props.apiHelper.getTextureStatusData();

      dispatch({
        type: "SET_TEXTURE_STATUS_DATA",
        textureStatusData: textureStatusData.data,
        isLoading: false,
      });
    };

    asyncFn();
    asyncFnShader();
    asyncFnTextureStatus();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={3}>
          <TextureHeader />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <TextureSidebar refreshAssetStatus={refreshAssetStatus} />
          <TextureList
            apiHelper={props.apiHelper}
            assetStatus={assetStatus}
          ></TextureList>
        </Col>
      </Row>
    </Container>
  );
};

export default Texture;
