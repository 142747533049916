import React, { useState } from "react";

// Image slider
import Lightbox from "yet-another-react-lightbox";
// thumbnails
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// captions
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ListGroup from "react-bootstrap/ListGroup";

const TextureOutputButton = (props) => {
  //const [message, setMessage] = useState(modelStatus);
  const [data, setData] = useState([]);
  // Image slider
  const [open, setOpen] = React.useState(false);
  // Thumbnails ref usage example
  const thumbnailsRef = React.useRef(null);

  const getListOfIamges = async (textureId) => {
    //alert("modelId : " + modelId);
    setOpen(true);
    // hardcoded for testing
    //modelId = "7b8a1d08af35420c37ae1d8375b13bd7";

    let modelOutput = await props.apiHelper.getTextureOutputData(textureId);

    let i = 1;
    let data1 = modelOutput.data.map((x) => {
      //   console.log({ x });
      let obj = {
        src: x.cloudFrontUrl,
        title: x.objectName,
        description: i++ + "\n" + x.requestedDate + "\n" + x.cameraName,
      };
      return obj;
    });
    setData(data1);
  };

  return (
    <div id="shaders">
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <button
            className="ui tiny button"
            type="button"
            onClick={() => getListOfIamges(props.texture.id)}
          >
            Output files
          </button>
        </ListGroup.Item>
        <ListGroup.Item>
          <Lightbox
            plugins={[Thumbnails, Captions]}
            open={open}
            close={() => setOpen(false)}
            slides={data}
            thumbnails={{ ref: thumbnailsRef }}
            on={{
              click: () => {
                (thumbnailsRef.current?.visible
                  ? thumbnailsRef.current?.hide
                  : thumbnailsRef.current?.show)?.();
              },
            }}
          />
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default TextureOutputButton;
