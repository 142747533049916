import React from "react";
import FootCard from "./FootCard";
import { useSelector } from "react-redux";
//import footList from "./foot_data.json";

const FootList = (props) => {
  // from json
  // const listOfFoot = footList;

  // from database
  const listOfFoot = useSelector((state) => state.footData);

  const renderFootList = listOfFoot.map((foot) => {
    return <FootCard key={foot.id} foot={foot} apiHelper={props.apiHelper} />;
  });

  return <div className="ui four cards">{renderFootList}</div>;
};

export default FootList;
