import React, { useState, useRef } from "react";
//import shaderList from "../../shader_data.json";
import { useSelector } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";

const TextureStatusButton = (props) => {
  let listOfTextureStatus = useSelector((state) => state.textureStatusData);
  let selectlistOfTextureStatus = listOfTextureStatus.filter((x) => x.id !== 3);
  // console.log(`listOfTextureStatus = ${JSON.stringify(listOfTextureStatus)}`);
  // console.log(
  //   `selectlistOfTextureStatus = ${JSON.stringify(selectlistOfTextureStatus)}`
  // );
  const selectRef = useRef();

  // buton diable and enable
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [textureId, setTextureId] = useState(props.texture.id);
  const [statusId, setStatusId] = useState(1);

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const handleSelectInput = (e) => {
    setTextureId(props.texture.id);
    setStatusId(e.target.value);
    enableButton();
  };

  const submitStatus = async (e) => {
    // Define metadata object
    setTextureId(props.texture.id);
    let bodyData = {
      textureId: textureId,
      statusId: statusId,
    };
    console.log(bodyData);
    // Upload files to API
    await props.apiHelper
      .submitTextureStatus(bodyData)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Texture's status updated successfully");
          // find status name from list of status
          let newStatus = listOfTextureStatus.find(
            (status) => status.id === Number(statusId)
          );
          props.refreshTexture(newStatus.id, newStatus.statusName);
          selectRef.current.value = "";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="shaders">
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <select
            ref={selectRef}
            className="ui dropdown"
            onChange={(e) => handleSelectInput(e)}
          >
            <option key="" value={""}>
              Select status
            </option>
            {selectlistOfTextureStatus.map((option) => (
              <option key={option.id} value={option.id}>
                {option["statusName"]}
              </option>
            ))}
          </select>
        </ListGroup.Item>
        <ListGroup.Item>
          <button
            className="ui tiny button"
            disabled={isButtonDisabled}
            onClick={() => submitStatus()}
          >
            Change status
          </button>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default TextureStatusButton;
