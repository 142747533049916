import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";

// added redux
import { useSelector, useDispatch } from "react-redux";

const ModelUploadButton = forwardRef((props, ref) => {
  const { id, rangeName, sizeDescription } = props.model;

  const dispatch = useDispatch();

  //const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);

  //const [range, setRange] = useState();
  //const [colour, setColour] = useState();

  //const handleFileInput = (e, colour) => {
  // redux isLoading var
  //const isLoading = useSelector((state) => state.isLoading);
  let modelData = useSelector((state) => state.modelData);
  let listOfModelStatus = useSelector((state) => state.modelStatusData);
  //choose file reference, reset the value
  const inputRef = useRef(null);

  // buton diable and enable
  //const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(
    props.assetStatusId === 1 ? false : true
  );

  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  const enableUploadToS3Button = () => {
    setUploadButtonDisabled(false);
  };

  const disableUploadToS3Button = () => {
    setUploadButtonDisabled(true);
  };

  function handleUploadButton(newAssetStatusId) {
    if (newAssetStatusId === 1) {
      enableButton();
      enableUploadToS3Button();
    } else {
      disableButton();
      disableUploadToS3Button();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      handleUploadButton,
    };
  });

  const handleFileInput = (e, id, rangeName, sizeDescription) => {
    setSelectedFile(e.target.files);
    enableUploadToS3Button();
    alert(
      "Selected file for rangeName: " +
        rangeName +
        " with size of " +
        sizeDescription
    );
  };

  const uploadModelFile = async (files) => {
    alert(`No of file selected: ${files.length}`);
    // Define metadata object
    let metadata = {
      id: id,
      rangeName: rangeName,
      sizeDescription: sizeDescription,
      resolution: "2048 x 2048",
      size: "25cm, 0.25m",
    };

    // Dispatch action to set loading state and set new ModelDate with exists_on_s3 set
    // dispatch({
    //   type: "SET_IS_LOADING",
    //   isLoading: true,
    // });
    // Upload files to API
    await props.apiHelper
      .uploadModelFile(files, metadata)
      .then((response) => {
        console.log(response.status);
        console.log(response.data);
        // Handle data
        if (response.status === 200) {
          // alert(statusId);
          alert("Model files uploded successfully");
          // find status name from list of status
          let newStatus = listOfModelStatus.find(
            (status) => Number(status.id) === 2
          );
          props.refreshModel(newStatus.id, newStatus.statusName);
          //disableButton();
          inputRef.current.value = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // find model in big list and set it to uploaded
    let newModelData = modelData.map((model) => {
      if (model.id === id) {
        model.assetStatusId = 2;
      }
      return model;
    });

    // Dispatch action to reset loading state and update current upload to exists
    dispatch({
      type: "SET_MODEL_DATA",
      modelData: newModelData,
      isLoading: false,
    });
  };

  return (
    <div>
      {/* {props.assetStatusId} */}
      <input
        ref={inputRef}
        disabled={isButtonDisabled}
        id="actual-btn"
        type="file"
        multiple
        onChange={(e) => handleFileInput(e, id, rangeName, sizeDescription)}
      />

      <button
        id={id}
        disabled={isUploadButtonDisabled}
        onClick={() => uploadModelFile(selectedFile)}
      >
        Upload to S3
      </button>
    </div>
  );
});

export default ModelUploadButton;
